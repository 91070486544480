<template>
  <div
    class='bg-white fixed w-full px-16 pb-16 overflow-y-auto z-10'
    style='top: 6rem; height: calc(100% - 6rem);'>
    <h1 class='pt-16 pb-8 text-2xl font-medium tracking-wide sticky top-0 bg-white z-20'>{{searchTypeLabel}} 검색 {{resultsCountString}}</h1>

    <table class='table-fixed text-sm'>
      <thead>
        <tr class='border-b'>
          <th class='pr-2 py-2 text-left'
            style='min-width: 12rem;'>{{entityLabel}}</th>
          <th class='pl-2 py-2 text-left'
            style='min-width: 12rem;'>{{investmentLabel}}</th>
          <th class='pl-2 py-2 text-left'
            style='min-width: 12rem;'>Status</th>
        </tr>
      </thead>
      <tbody>
        <search-result-investment v-for='investment in investmentSearchResults'
          :key='`investment-${investment.id}`'
          :investment='investment'
          class='border-b hover:bg-blue-100 rounded cursor-pointer'
          @click.native='investmentDrilldown(investment)' />
      </tbody>
    </table>
    <!-- Add these back in later: Hana (2021/09/01) -->
<!--     <contact-card v-for='contact in contactSearchResults'
          :contact='contact'
          :key='`contact-${contact.id}`'
          class='max-w-xl shadow-lg mt-10'
          @click.native='contactDrilldown(contact)'></contact-card>
    <meeting-note-card v-for='meetingNote in meetingNoteSearchResults'
          :meetingNote='meetingNote'
          :key='`meeting-mote-${meetingNote.id}`'
          class='max-w-xl shadow-lg mt-10'
          @click.native='openMeetingNoteEdit(meetingNote.id)'></meeting-note-card> -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SearchResultInvestment from '@/components/search/SearchResultInvestment.vue'
// import ContactCard    from '@/components/contacts/ContactCard.vue'
// import MeetingNoteCard from '@/components/meeting_notes/MeetingNoteCard.vue'
export default {
  name: 'SearchResults',
  components: {
    // ContactCard,
    SearchResultInvestment,
    // MeetingNoteCard,
  },
  data () {
    return {
      searchFocus: false,
      searchText: ' ',
    }
  },
  computed: {
    resultsCount () {
      return this.investmentSearchResults.length + this.contactSearchResults.length + this.meetingNoteSearchResults.length
    },
    resultsCountString () {
      return `(${this.resultsCount})`
    },
    ...mapState('investments', {
      investmentSearchResults: 'searchResults'
    }),
    ...mapState('contacts', {
      contactSearchResults: 'searchResults'
    }),
    ...mapState('meetingNotes', {
      meetingNoteSearchResults: 'searchResults'
    }),
    entityLabel () {
      return '회사'
    },
    investmentLabel () {
      return '명칭'
    },
    searchTypeLabel () {
      return 'Investment'
    }
  },
  methods: {
    ...mapActions('investments', [
      'investmentDetailsOpen',
    ]),
    ...mapActions('contacts', [
      'contactDrilldownOpen',
    ]),
    ...mapActions('meetingNotes', [
      'getMeetingNoteDetails',
    ]),
    contactDrilldown (contact) {
      this.contactDrilldownOpen(contact)
    },
    investmentDrilldown (investment) {
      this.investmentDetailsOpen(investment)
    },
    openMeetingNoteEdit (meetingNoteId) {
      this.getMeetingNoteDetails(meetingNoteId)
    },
  },
}
</script>
