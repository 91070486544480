<template>
  <tr class=''>
    <td class='pr-2 py-2'>{{investment.entity_name}}</td>
    <td class='pl-2 py-2'>{{investment.investment_name}}</td>
    <td class='pl-2 py-2'>{{investment.investment_status}}</td>
  </tr>
</template>

<script>
export default {
  name: 'SearchResultInvestment',
  props: [
    'investment',
  ],
}
</script>
